const products = [
    {
      id: 'B3',
      namn: 'Balpress B3',
      typ: 'Balpressar',
      volym: 'Liten',
      balvikt: { cardboard: '30-60', plastic: '40-60' },
      fyllningsöppning: '770 x 460',
      balstorlek: '770 x 500 x 600',
      kategori: ['Vertikala balpressar'],
      serie: 'B-serien',
      avfallstyp: ['Plastfolie', 'Wellpapp'],
      beskrivning: 'Rätt val när du behöver en liten balpress men inte vill kompromissa med balpressens prestanda.',
      imageUrl: '/product-pictures/B3/B3.webp',
      thumbnails: ['/product-pictures/B3/B3_t1.PNG', '/product-pictures/B3/B3_t2.PNG', '/product-pictures/B3/B3_t3.PNG', '/product-pictures/B3/B3_t4.PNG'],
      dimensionsImg: '/brochures/B3_size.svg',
      brochureUrl: '/brochures/PDF_BROCHURE_B3.pdf',
      presstryck: '3',
      strömförsörjning: '1x230V 50Hz 10A',
      motorstorlek: '1.1',
      ljudnivå: '65-68',
      arbetscykeltid: '24',
      mått: '940 x 715 x 1985',
      vikt: '290',
      fyllningshöjd: '645',
      presskammarens_höjd: '1000',
      slaglängd: '550',
      antal_band: '2',
      bindningstyp: 'Balband',
      ljus_vid_full_bal: 'Ja',
      utbalning: 'Manuell',
      videoId: 'yrkIB2YHl_8?si=C6PjbbwfSeY6Xl2o',
      användning_manual: { 
          one: 'Fyll ditt plast- eller kartongavfall i balpressen.', 
          two: 'En indikator visar dig när kammaren är full.', 
          three: 'Slå av och skjut ut den färdiga balen.', 
          four: 'Ta bort balen och lagra den tills insamlingen.'
      },
      höjdpunkter: {
        rubrik_ett: 'Små fotavtryck balpress',
        text_ett: 'B3 kombinerar hög komprimering med ett litet basområde och låg höjd. Den passar där du behöver den.',
        rubrik_två: 'Hanterar balar enkelt',
        text_två: 'Du kan enkelt ta bort den färdiga balen med handvagnen som medföljer.',
        rubrik_lista: 'B3 - Små fotavtryckspressare för trånga utrymmen:',
        lista: [
          'Enkel och säker att använda',
          'Små hanterbara balar',
          'Enkel installation',
          'Litet basområde (0,67 m2) och låg totalhöjd',
          'Fungerar på enfas strömförsörjning',
          'Säker och kontrollerad öppning av nedre dörren',
          'Pulverlackering ger hållbar finish i många färger',
        ]
      }
  },
  {
      id: 'B4',
      namn: 'Balpress B4',
      typ: 'Balpressar',
      volym: 'Liten',
      balvikt: { cardboard: '40-60', plastic: '50-70' },
      fyllningsöppning: '770 x 460',
      balstorlek: '770 x 500 x 600',
      kategori: ['Vertikala balpressar'],
      serie: 'B-serien',
      avfallstyp: ['Plastfolie', 'Wellpapp'],
      beskrivning: 'Denna B4-balspress kommer med fina extrafunktioner jämfört med andra småfotavtryckspressare. Med 4 ton presskraft, djupa slag och effektiva hållare når du ett högt kompakteringsförhållande.',
      imageUrl: '/product-pictures/B4/B4.webp',
      thumbnails: ['/product-pictures/B4/B4_t1.PNG', '/product-pictures/B4/B4_t2.PNG', '/product-pictures/B4/B4_t3.PNG', '/product-pictures/B4/B4_t4.PNG'],
      dimensionsImg: '/brochures/B4_size.svg',
      brochureUrl: '/brochures/PDF_BROCHURE_B4.pdf',
      presstryck: '4',
      strömförsörjning: '1x230V 50Hz 10A',
      motorstorlek: '1.5',
      ljudnivå: '57-60',
      arbetscykeltid: '28',
      mått: '940 x 715 x 1985',
      vikt: '300',
      fyllningshöjd: '645',
      presskammarens_höjd: '1000',
      slaglängd: '650',
      antal_band: '2',
      bindningstyp: 'Balband',
      ljus_vid_full_bal: 'Ja',
      utbalning: 'Automatisk',
      videoId: 'yrkIB2YHl_8?si=C6PjbbwfSeY6Xl2o',
      användning_manual: {
        one: 'Fyll ditt plast- eller kartongavfall i balpressen.',
        two: 'En indikator visar dig när kammaren är full.',
        three: 'Slå av och skjut ut den färdiga balen.',
        four: 'Ta bort balen och lagra den tills insamlingen.'
      },
      höjdpunkter: {
        rubrik_ett: 'Kompakt och kraftfull balpress',
        text_ett: 'B4 är en kompakt balpress med ett litet basområde och låg höjd - och en kraftfull presskraft.',
        rubrik_två: 'Optimal arbetsmiljö',
        text_två: 'Du matar ut den färdiga balen med en säker tvåhandsoperation.',
        rubrik_lista: 'B4 - Lätt att passa in liten fotavtryckpressare:',
        lista: [
          'Enkel kontrollpanel',
          'Automatisk balutkastning',
          'Automatisk fullbalansindikator',
          'Mycket låg ljudnivå',
          'Låg total bredd (940 mm) och höjd',
          'Kompakta balar',
          'Frontstyrd för installation i smala utrymmen',
          'Enfas strömanslutning',
          'Pulverlackering ger hållbar finish i många färger',
        ]
      }
  },
  {
      id: 'B4_Textile',
      namn: 'Balpress B4 Textile',
      typ: 'Balpressar',
      volym: 'Liten',
      balvikt: { textilier: '25-80' },
      fyllningsöppning: '770 x 460',
      balstorlek: '770 x 500 x 600',
      kategori: ['Vertikala balpressar'],
      serie: 'B-serien',
      avfallstyp: ['Textilier', 'Kläder', 'Tyger'],
      beskrivning: 'En specialtillverkad balpress för kläder och textilier. Presskammaren är designad utan återfjädring så att det inte uppstår skador på textilierna eller kläderna.',
      imageUrl: '/product-pictures/B4/B4.webp',
      thumbnails: ['/product-pictures/B4/B4_t1.PNG', '/product-pictures/B4/B4_t2.PNG', '/product-pictures/B4/B4_t3.PNG', '/product-pictures/B4/B4_t4.PNG'],
      dimensionsImg: '/brochures/B4_size.svg',
      brochureUrl: '/brochures/PDF_BROCHURE_B4T.pdf',
      presstryck: '4',
      strömförsörjning: '1x230V 50Hz 10A',
      motorstorlek: '1.5',
      ljudnivå: '57-60',
      arbetscykeltid: '28',
      mått: '975 x 715 x 1985',
      vikt: '300',
      fyllningshöjd: '645',
      presskammarens_höjd: '1000',
      slaglängd: '650',
      antal_band: '2',
      bindningstyp: 'Balband',
      ljus_vid_full_bal: 'Ja',
      utbalning: 'Automatisk',
      videoId: 'yrkIB2YHl_8?si=C6PjbbwfSeY6Xl2o',
      användning_manual: {
        one: 'Fyll ditt plast- eller kartongavfall i balpressen.',
        two: 'En indikator visar dig när kammaren är full.',
        three: 'Slå av och skjut ut den färdiga balen.',
        four: 'Ta bort balen och lagra den tills insamlingen.'
      },
      höjdpunkter: {
        rubrik_ett: 'Speciellt för textilier',
        text_ett: 'För att komprimera kläder är vår B4 Textile idealisk. Special utvecklad utan återfjädring för att skydda kläderna.',
        rubrik_två: 'Små lätt hanterbara balar',
        text_två: 'Denna balpress gör små hanterbara balar som är lätta att transportera och stapla.',
        rubrik_lista: 'B4 Textil - Specialpressare för kläder och textilier:',
        lista: [
          'Inga återfjädrings hållare inne i kammaren',
          'Detta skyddar kläderna från skador',
          'Kompakta balar mäter bara 70 x 50 x 35-65 cm',
          'Balar är lätta att transportera och stapla',
          'En monterad kniv gör det enkelt att klippa banden',
          'Automatisk förebyggande av överbelastning i kammaren.',
          'Pulverlackering ger hållbar finish i många färger',
        ]
      }
  },
  {
      id: 'B5_Wide',
      namn: 'Balpress B5 Wide',
      typ: 'Balpressar',
      volym: 'Liten',
      balvikt: { cardboard: '80-110', plastic: '90-130' },
      fyllningsöppning: '1000 x 500',
      balstorlek: '1000 x 700 x 800',
      kategori: ['Vertikala balpressar'],
      serie: 'B-serien',
      avfallstyp: ['Plastfolie', 'Wellpapp', 'Papper'],
      beskrivning: 'B5 Wide har konstruerats för att betjäna användare med mindre mängder avfall, men det är lämpligt att hantera skrymmande material.',
      imageUrl: '/product-pictures/B5W/B5W.webp',
      thumbnails: ['/product-pictures/B5W/B5W_t1.PNG', '/product-pictures/B5W/B5W_t2.PNG', '/product-pictures/B5W/B5W_t3.PNG', '/product-pictures/B5W/B5W_t4.PNG',],
      dimensionsImg: '/brochures/B5W_size.svg',
      brochureUrl: '/brochures/PDF_BROCHURE_B5W.pdf',
      presstryck: '5',
      strömförsörjning: '1x230V 50Hz 10A',
      motorstorlek: '1.1',
      ljudnivå: '65-68',
      arbetscykeltid: '33',
      mått: '1360 x 895 x 2370',
      vikt: '600',
      fyllningshöjd: '915',
      presskammarens_höjd: '1305',
      slaglängd: '750',
      antal_band: '3',
      bindningstyp: 'Balband',
      ljus_vid_full_bal: 'Ja',
      utbalning: 'Automatisk',
      videoId: 'yrkIB2YHl_8?si=C6PjbbwfSeY6Xl2o',
      användning_manual: {
        one: 'Fyll ditt plast- eller kartongavfall i balpressen.',
        two: 'En indikator visar dig när kammaren är full.',
        three: 'Slå av och skjut ut den färdiga balen.',
        four: 'Ta bort balen och lagra den tills insamlingen.'
      },
      höjdpunkter: {
        rubrik_ett: 'För stora och skrymmande material',
        text_ett: 'En extra bred balpress avsedd att hantera stora och skrymmande material. De största kartongerna kan kastas in utan att brytas ner.',
        rubrik_två: 'Enkel installation och bred applikation',
        text_två: 'Denna balpress arbetar med enfas strömförsörjning, vilket gör det enkelt att installera i en mängd olika miljöer.',
        rubrik_lista: 'B5 Wide - Perfekt för hantering av stora lådor:',
        lista: [
          'Hög kapacitet - stor utgång',
          'Bred fyllningsöppning (1 meter)',
          'Effektiva hållare håller ner komprimerat material',
          'Balen tippas automatiskt ut',
          'Front-placerade bandrullar för enkelt byte av rullar',
          'Fungerar på enfas strömförsörjning',
          'Uppfyller EU: s säkerhetsstandard EN16500',
          'Pulverlackering ger hållbar finish i många färger',
        ]
      }
  },
  {
      id: 'X10',
      namn: 'Balpress X10',
      typ: 'Balpressar',
      volym: 'Liten',
      balvikt: { cardboard: '70-90', plastic: '80-110' },
      fyllningsöppning: '800 x 610',
      balstorlek: '800 x 600 x 600',
      kategori: ['Vertikala balpressar'],
      serie: 'X-serien',
      avfallstyp: ['Wellpapp', 'Plastfolie', 'Papper', 'PET flaskor', 'Aluminium burkar', 'Metall burkar', 'Hårdplast', 'Textilier', 'EPS Skumplast'],
      beskrivning: 'X10 är en kompakt balpress med hög kapacitet och prestanda. Idealisk för placering på begränsad yta',
      imageUrl: '/product-pictures/X10/X10.jpg',
      thumbnails: ['/product-pictures/X10/X10_t1.PNG', '/product-pictures/X10/X10_t2.PNG', '/product-pictures/X10/X10_t3.PNG', '/product-pictures/X10/X10_t4.PNG'],
      dimensionsImg: '/brochures/X10_size.svg',
      brochureUrl: '/brochures/PDF_BROCHURE_X10.pdf',
      presstryck: '10',
      strömförsörjning: '1x230V 50Hz 10A',
      motorstorlek: '1.5',
      ljudnivå: '59-60',
      arbetscykeltid: '38',
      mått: '1315 x 1040 x 1905',
      vikt: '665',
      fyllningshöjd: '790',
      presskammarens_höjd: '1295',
      slaglängd: '845',
      antal_band: '3',
      bindningstyp: 'Balband',
      ljus_vid_full_bal: 'Ja',
      utbalning: 'Automatisk',
      videoId: 'yjC1_Bpgq1Q?si=R9ZoZl0IcemXLmyN',
      användning_manual: {
        one: 'Fyll ditt plast- eller kartongavfall i balpressen.',
        two: 'En indikator visar dig när kammaren är full.',
        three: 'Slå av och skjut ut den färdiga balen.',
        four: 'Ta bort balen och lagra den tills insamlingen.'
      },
      höjdpunkter: {
        rubrik_ett: 'Liten och effektiv balpress',
        text_ett: 'Den här balpressen har en press styrka som är överlägsen andra balpressar i denna storlek.',
        rubrik_två: 'Ergonomisk med säker drift',
        text_två: 'Med ett enkelt tvåhands grepp matas balen ut. Detta eleminerar tunga lyft.',
        rubrik_lista: 'X10 - Kompakt balpress med hög kapacitet:',
        lista: [
          'Kompakt och stark',
          'Invändiga krysscylindrar ger en väldigt låg höjd',
          'Korta cykeltider med snabb städning',
          'Press styrka på 10 ton - en hårt arbetande balpress med hög kapacitet',
          'Liten yta (<1.5 m2)',
          'Stabil komprimering - håller balen under tryck medan du binder',
          'Tillgänglig med både enfas och trefas anslutning',
          'Den låga dörren öppnas säker med en gängad ratt',
          'Pulverlackering ger hållbar finish i många färger',
        ]
      }
  },
  {
      id: 'PC24_HD_Galvaniserad',
      namn: 'Balpress PC24 HD Galvaniserad',
      typ: 'Balpressar',
      volym: 'Liten',
      balvikt: { plastic_bottles: '30-105', aluminium_cans: '15-55' },
      fyllningsöppning: '470 x 470',
      balstorlek: '600 x 500 x 200-650',
      kategori: ['Vertikala balpressar'],
      serie: 'PC-serien',
      avfallstyp: ['PET flaskor', 'Aluminium burkar'],
      beskrivning: 'Modell PC24 HD Galvaniserad är en kraftfull balpress designad till komprimering av de hårdaste materialen.',
      imageUrl: '/product-pictures/PC24/PC24.jpg',
      thumbnails: ['/product-pictures/PC24/PC24_t1.PNG', '/product-pictures/PC24/PC24_t2.PNG', '/product-pictures/PC24/PC24_t3.PNG', '/product-pictures/PC24/PC24_t4.PNG'],
      dimensionsImg: '/brochures/PC24_size.svg',
      brochureUrl: '/brochures/PDF_BROCHURE_PC24.pdf',
      presstryck: '24',
      strömförsörjning: '3x400V 50Hz 16A',
      motorstorlek: '4.0',
      ljudnivå: '62-64',
      arbetscykeltid: '40',
      mått: '1125 x 1210 x 2980',
      vikt: '900',
      fyllningshöjd: '1220',
      presskammarens_höjd: '1200',
      slaglängd: '1100',
      antal_band: '2',
      bindningstyp: 'Balband',
      ljus_vid_full_bal: 'Ja',
      utbalning: 'Manuell',
      videoId: 'IMx-jZULysQ?si=sDJibGZ38oRU4ruK',
      användning_manual: {
        one: 'Fyll ditt plast- eller kartongavfall i balpressen.',
        two: 'En indikator visar dig när kammaren är full.',
        three: 'Slå av och skjut ut den färdiga balen.',
        four: 'Ta bort balen och lagra den tills insamlingen.'
      },
      höjdpunkter: {
        rubrik_ett: 'Utdragning av vätska',
        text_ett: 'Avfallspressaren PC24 HD komprimerar t.ex. plastflaskor och aluminiumburkar, samtidigt som den avleder rester av vätska. Den överflödiga vätskan samlas i en inbyggd reservoar.',
        rubrik_två: 'Aluminiumburkar',
        text_två: 'PC24 HD pressar aluminiumburkar så hårt samman att de förblir ihoppressade.',
        rubrik_lista: 'PC24 HD Galvaniserad - en flaska- och burkpress:',
        lista: [
          'Solid konstruktion',
          'Mycket kompakta balar',
          'Vippdörr gör påfyllning ännu enklare',
          'Helt slutet kammare med inbyggd reservoar',
          'Målad version av pressen är valbar',
        ]
      }
  },
  {
      id: 'B20',
      namn: 'Balpress B20',
      typ: 'Balpressar',
      volym: 'Mellan',
      balvikt: { cardboard: '200-250', plastic: '250-300' },
      fyllningsöppning: '1200 x 480',
      balstorlek: '1200 x 800 x 800',
      kategori: ['Vertikala balpressar'],
      serie: 'B-serien',
      avfallstyp: ['Plastfolie', 'Wellpapp', 'Papper'],
      beskrivning: 'B20 är en mycket flexibel maskin. Den passar till många olika användningsområde, ex större butiker, lager och andra industrier.',
      imageUrl: '/product-pictures/B20/B20.webp',
      thumbnails: ['/product-pictures/B20/B20_t1.PNG', '/product-pictures/B20/B20_t2.PNG', '/product-pictures/B20/B20_t3.PNG', '/product-pictures/B20/B20_t4.PNG'],
      dimensionsImg: '/brochures/B20_size.svg',
      brochureUrl: '/brochures/PDF_BROCHURE_B20.pdf',
      presstryck: '20',
      strömförsörjning: '3x400V 50Hz 16A',
      motorstorlek: '4.0',
      ljudnivå: '62-64',
      arbetscykeltid: '36 / 21',
      mått: '1775 x 1030 x 2790',
      vikt: '1250',
      fyllningshöjd: '965',
      presskammarens_höjd: '1410',
      slaglängd: '750',
      antal_band: '4',
      bindningstyp: 'Balband',
      ljus_vid_full_bal: 'Ja',
      utbalning: 'Automatisk',
      videoId: 'yrkIB2YHl_8?si=C6PjbbwfSeY6Xl2o',
      användning_manual: {
        one: 'Fyll ditt plast- eller kartongavfall i balpressen.',
        two: 'En indikator visar dig när kammaren är full.',
        three: 'Slå av och skjut ut den färdiga balen.',
        four: 'Ta bort balen och lagra den tills insamlingen.'
      },
      höjdpunkter: {
        rubrik_ett: 'Felxibel balpress till olika typer av industrier',
        text_ett: 'Komprimerar olika typer av återvinningsbara material såsom kartong, papper och mjukplast.',
        rubrik_två: 'Balarna håller perfekt pallstorlek',
        text_två: 'Balen storlek är optimerad för transport och färdiga balar passar på en standard EUR pall.',
        rubrik_lista: 'B20 - Komprimering i stor skala',
        lista: [
          'Enkelt att binda balarna',
          'Hög kapacitet',
          'Kort presscykel',
          'Frontplacerade bandrullar för installation nära väggar',
          'Hög komprimering',
          'Balarna är anpassade till standard EUR pallar',
          'Pulverlackering ger hållbar finish i många färger',
        ]
      }
  },
  {
      id: 'X25',
      namn: 'Balpress X25',
      typ: 'Balpressar',
      volym: 'Mellan',
      balvikt: { cardboard: '200-300', plastic: '250-350' },
      fyllningsöppning: '1090 x 530',
      balstorlek: '1200 x 800 x 800',
      kategori: ['Vertikala balpressar'],
      serie: 'X-serien',
      avfallstyp: ['Wellpapp', 'Plastfolie', 'Papper', 'PET flaskor', 'Aluminium burkar', 'Metall burkar', 'Hårdplast', 'Textilier', 'EPS Skumplast'],
      beskrivning: 'Utrustad med invändiga krysslagda kolvar ger en stabil komprimering, en kraftfull press styrka och en låg höjd på maskinen.',
      imageUrl: '/product-pictures/X25/X25.jpg',
      thumbnails: ['/product-pictures/X25/X25_t1.PNG', '/product-pictures/X25/X25_t2.PNG', '/product-pictures/X25/X25_t3.PNG', '/product-pictures/X25/X25_t4.PNG'],
      dimensionsImg: '/brochures/X25_size.svg',
      brochureUrl: '/brochures/PDF_BROCHURE_X25.pdf',
      presstryck: '25',
      strömförsörjning: '3x400V 50Hz 16A',
      motorstorlek: '4.0',
      ljudnivå: '59-60',
      arbetscykeltid: '23',
      mått: '1755 x 1255 x 1995',
      vikt: '1410',
      fyllningshöjd: '955',
      presskammarens_höjd: '1380',
      slaglängd: '950',
      antal_band: '4',
      bindningstyp: 'Balband / Stålband',
      ljus_vid_full_bal: 'Ja',
      utbalning: 'Automatisk',
      videoId: 'yjC1_Bpgq1Q?si=R9ZoZl0IcemXLmyN',
      användning_manual: {
        one: 'Fyll ditt plast- eller kartongavfall i balpressen.',
        two: 'En indikator visar dig när kammaren är full.',
        three: 'Slå av och skjut ut den färdiga balen.',
        four: 'Ta bort balen och lagra den tills insamlingen.'
      },
      höjdpunkter: {
        rubrik_ett: 'Passar i trånga utrymmen med lågt i tak',
        text_ett: 'Invändiga krysslagda kolvar gör denna balpress väldigt låg och lätt att installera.',
        rubrik_två: 'Uppnå hög balvikt',
        text_två: 'Den överlägsna presstyrkan kombinerad med långa kolvar gör att ni uppnår en hög balvikt.',
        rubrik_lista: 'X25 - Stark balpress för komprimering av avfall:',
        lista: [
          'För olika typer av material - till och med kraftigt expanderande',
          'Stor och bred kammare',
          'Hållare motverkar material expansion i kammaren',
          'Kort cykel påskyndar städningen',
          'Rörliga delar är skyddade innuti maskinen',
          'Låg höjd',
          'Pulverlackering ger hållbar finish i många färger',
        ]
      }
  },
  {
      id: 'X25_Galvaniserad',
      namn: 'Balpress X25 Galvaniserad',
      typ: 'Balpressar',
      volym: 'Mellan',
      balvikt: { cardboard: '200-300', plastic: '250-350' },
      fyllningsöppning: '1090 x 530',
      balstorlek: '1200 x 800 x 800',
      kategori: ['Vertikala balpressar'],
      serie: 'X-serien',
      avfallstyp: ['Wellpapp', 'Plastfolie', 'Papper', 'PET flaskor', 'Aluminium burkar', 'Metall burkar', 'Hårdplast', 'Flytande', 'EPS Skumplast'],
      beskrivning: 'En galvaniserad balpress som är idealisk för våta eller krävande miljöer. Perfekt för hantering av kartong och plastavfall, samt PET-flaskor och aluminium.',
      imageUrl: '/product-pictures/X25G/X25G.jpg',
      thumbnails: ['/product-pictures/X25G/X25G_t1.PNG', '/product-pictures/X25G/X25G_t2.PNG', '/product-pictures/X25G/X25G_t3.PNG', '/product-pictures/X25G/X25G_t4.PNG'],
      dimensionsImg: '/brochures/X25G_size.svg',
      brochureUrl: '/brochures/PDF_BROCHURE_X25G.pdf',
      presstryck: '25',
      strömförsörjning: '3x400V 50Hz 16A',
      motorstorlek: '4.0',
      ljudnivå: '59-60',
      arbetscykeltid: '23',
      mått: '1755 x 1255 x 1995',
      vikt: '1410',
      fyllningshöjd: '955',
      presskammarens_höjd: '1380',
      slaglängd: '950',
      antal_band: '4',
      bindningstyp: 'Balband / Stålband',
      ljus_vid_full_bal: 'Ja',
      utbalning: 'Automatisk',
      videoId: 'yjC1_Bpgq1Q?si=R9ZoZl0IcemXLmyN',
      användning_manual: {
        one: 'Fyll ditt plast- eller kartongavfall i balpressen.',
        two: 'En indikator visar dig när kammaren är full.',
        three: 'Slå av och skjut ut den färdiga balen.',
        four: 'Ta bort balen och lagra den tills insamlingen.'
      },
      höjdpunkter: {
        rubrik_ett: 'För utmanande eller våta miljöer',
        text_ett: 'Denna balpress är särskilt användbar i miljöer där det inte är särskilda hygienkrav på rostfritt, med där kraven på maskinens ytskikt är så svåra att en vanlig lackerad balpress kommer få en kort livstid.',
        rubrik_lista: 'X25 Galvaniserad - Balpress för krävande miljöer:',
        lista: [
          'Galvaniserad enligt DS/EN 1461:2009',
          'Resisten ytskikt',
          'Ingen flagnande färg',
          'Användbar för placering utomhus',
          'Enkel installation',
          'Låg höjd',
        ]
      }
  },
  {
      id: 'X30_LP',
      namn: 'Balpress X30 LP',
      typ: 'Balpressar',
      volym: 'Mellan',
      balvikt: { cardboard: '225-325', plastic: '275-375' },
      fyllningsöppning: '1090 x 530',
      balstorlek: '1200 x 800 x 800',
      kategori: ['Vertikala balpressar'],
      serie: 'X-serien',
      avfallstyp: ['Wellpapp', 'Plastfolie', 'Papper', 'PET flaskor', 'Aluminium burkar', 'Metall burkar', 'Hårdplast', 'Textilier', 'EPS Skumplast'],
      beskrivning: 'X30 LP modellen är väldigt enkel att installera pga sin kompakta design. Samtidigt är det en väldigt kraftfull maskin med sina 30 tons presskraft.',
      imageUrl: '/product-pictures/X30LP/X30LP.webp',
      thumbnails: ['/product-pictures/X30LP/X30LP_t1.PNG', '/product-pictures/X30LP/X30LP_t2.PNG', '/product-pictures/X30LP/X30LP_t3.PNG', '/product-pictures/X30LP/X30LP_t4.PNG',],
      dimensionsImg: '/brochures/X30LP_size.svg',
      brochureUrl: '/brochures/PDF_BROCHURE_X30LP.pdf',
      presstryck: '30',
      strömförsörjning: '3x400V 50Hz 16A',
      motorstorlek: '4.0',
      ljudnivå: '59-60',
      arbetscykeltid: '25',
      mått: '1760 x 1255 x 1995',
      vikt: '1450',
      fyllningshöjd: '1240',
      presskammarens_höjd: '1380',
      slaglängd: '950',
      antal_band: '4',
      bindningstyp: 'Balband / Stålband',
      ljus_vid_full_bal: 'Ja',
      utbalning: 'Automatisk',
      videoId: 'yjC1_Bpgq1Q?si=R9ZoZl0IcemXLmyN',
      användning_manual: {
        one: 'Fyll ditt plast- eller kartongavfall i balpressen.',
        two: 'En indikator visar dig när kammaren är full.',
        three: 'Slå av och skjut ut den färdiga balen.',
        four: 'Ta bort balen och lagra den tills insamlingen.'
      },
      höjdpunkter: {
        rubrik_ett: 'Hantera stora avfallsvolymer',
        text_ett: 'Snabb cykeltid, brett inkast och en överlägsen presskraft gör det enkelt att hantera stora volymer avfall under kort tid.',
        rubrik_två: 'Enkel att placera där du önskar',
        text_två: 'Med en totalhöjd på under 2 meter blir det enkelt att installera maskinen var helst du önskar. Med kraftfulla korslagda cylindrar och en kraftull design så blir detta en väldigt hållbar maskin.',
        rubrik_lista: 'X30 LP - hög kapacitets balpress:',
        lista: [
          'För många olika avfallsslag',
          'Låg totalhöjd',
          'Hög presskraft',
          'Mycket låg ljudnivå',
          'Snabb cykeltid och hög effektivitet',
          'Pulverlackering ger hållbar finish i många färger',
        ]
      }
  },
  {
      id: 'B30',
      namn: 'Balpress B30',
      typ: 'Balpressar',
      volym: 'Mellan',
      balvikt: { cardboard: '280-350', plastic: '350-450' },
      fyllningsöppning: '1200 x 560',
      balstorlek: '1200 x 800 x 950',
      kategori: ['Vertikala balpressar'],
      serie: 'B-serien',
      avfallstyp: ['Plastfolie', 'Wellpapp', 'Papper'],
      beskrivning: 'B30 är en flexibel och kraftfull maskin för många olika materialslag. Den breda inkastöppningen gör det enkelt att lasta in stora kartonger och andra skrymmande avfall.',
      imageUrl: '/product-pictures/B30/B30.webp',
      thumbnails: ['/product-pictures/B30/B30_t1.PNG', '/product-pictures/B30/B30_t2.PNG', '/product-pictures/B30/B30_t3.PNG', '/product-pictures/B30/B30_t4.PNG'],
      dimensionsImg: '/brochures/B30_size.svg',
      brochureUrl: '/brochures/PDF_BROCHURE_B30.pdf',
      presstryck: '30',
      strömförsörjning: '3x400V 50Hz 16A',
      motorstorlek: '4.0',
      ljudnivå: '62-64',
      arbetscykeltid: '34 / 51',
      mått: '1775 x 1025 x 2940',
      vikt: '1560',
      fyllningshöjd: '1055',
      presskammarens_höjd: '1560',
      slaglängd: '750',
      antal_band: '4',
      bindningstyp: 'Balband / Stålband',
      ljus_vid_full_bal: 'Ja',
      utbalning: 'Automatisk',
      videoId: 'yrkIB2YHl_8?si=C6PjbbwfSeY6Xl2o',
      användning_manual: {
        one: 'Fyll ditt plast- eller kartongavfall i balpressen.',
        two: 'En indikator visar dig när kammaren är full.',
        three: 'Slå av och skjut ut den färdiga balen.',
        four: 'Ta bort balen och lagra den tills insamlingen.'
      },
      höjdpunkter: {
        rubrik_ett: 'Extra brett inkast och lätt att fylla',
        text_ett: 'B30 är en balpress med extra brett inkast. Det är väldigt smidigt att mata in större kartonger och annat skrymmande material.',
        rubrik_två: 'Enkel att operera',
        text_två: 'En lite lampa indikerar när det att dags att bala ur. Detta mimimerar risken att överfylla kammaren. Balen balas ur automatiskt genom ett tvåhandsgrepp.',
        rubrik_lista: 'B30 - Hantera skrymmande material:',
        lista: [
          'Brett inkast och stor kammare',
          'Hakar som förhindrar att komprimerat material expanderar',
          'Att balbanden sitter framtill inebär att man inte behöver gå bakom maskinen',
          'Balbanden skärs enkelt av på en kniv som är monterad på maskinens framsida',
          'Indikering av full bal',
          'Pulverlackering ger hållbar finish i många färger',
        ]
      }
  },
  {
      id: 'X30',
      namn: 'Balpress X30',
      typ: 'Balpressar',
      volym: 'Mellan',
      balvikt: { cardboard: '275-350', plastic: '325-425' },
      fyllningsöppning: '1090 x 655',
      balstorlek: '1200 x 800 x 1000',
      kategori: ['Vertikala balpressar'],
      serie: 'X-serien',
      avfallstyp: ['Wellpapp', 'Plastfolie', 'Papper', 'PET flaskor', 'Aluminium burkar', 'Metall burkar', 'Hårdplast', 'Textilier', 'EPS Skumplast'],
      beskrivning: 'Denna X30 balpress passar utmärkt för stora kvantiteter av avfall. Det är en kompakt maskin med hög kapacitet och extra ordinär presskraft. Den extra breda inkastöppningen underlättar i den vardagliga hanteringen av maskinen.',
      imageUrl: '/product-pictures/X30/X30.webp',
      thumbnails: ['/product-pictures/X30/X30_t1.PNG', '/product-pictures/X30/X30_t2.PNG', '/product-pictures/X30/X30_t3.PNG', '/product-pictures/X30/X30_t4.PNG'],
      dimensionsImg: '/brochures/X30_size.svg',
      brochureUrl: '/brochures/PDF_BROCHURE_X30.pdf',
      presstryck: '30',
      strömförsörjning: '3x400V 50Hz 16A',
      motorstorlek: '4.0',
      ljudnivå: '59-60',
      arbetscykeltid: '25',
      mått: '1760 x 1255 x 2295',
      vikt: '1710',
      fyllningshöjd: '1130',
      presskammarens_höjd: '1675',
      slaglängd: '940',
      antal_band: '4',
      bindningstyp: 'Balband / Stålband',
      ljus_vid_full_bal: 'Ja',
      utbalning: 'Automatisk',
      videoId: 'yjC1_Bpgq1Q?si=R9ZoZl0IcemXLmyN',
      användning_manual: {
        one: 'Fyll ditt plast- eller kartongavfall i balpressen.',
        two: 'En indikator visar dig när kammaren är full.',
        three: 'Slå av och skjut ut den färdiga balen.',
        four: 'Ta bort balen och lagra den tills insamlingen.'
      },
      höjdpunkter: {
        rubrik_ett: 'Hantera volymiöst avfall',
        text_ett: 'Den breda kammaren och den extra ordinära presskraften gör X30 till den perfekta maskinen för att hantera stora kartonger och andra volymiösa avfall.',
        rubrik_två: 'En balpress för de flesta sorters avfall',
        text_två: 'Använd denna maskin för att komprimera de flesta sorters avfall inklusive PET flaskor, plaster, aluminium burkar, big bags och andra expanderande plast material.',
        rubrik_lista: 'X30 - Utmärkt balpress för stora volymer av avfall:',
        lista: [
          'Komprimerar väldigt volymiöst avfall',
          'För de flesta avfallsslag',
          'Hakar motverkar att materialet expanderare tillbaka i kammaren',
          'Optimal komprimering oavsett material',
          'Pulverlackering ger hållbar finish i många färger',
        ]
      }
  },
  {
      id: 'X30_Galvaniserad',
      namn: 'Balpress X30 Galvaniserad',
      typ: 'Balpressar',
      volym: 'Mellan',
      balvikt: { cardboard: '275-350', plastic: '325-425' },
      fyllningsöppning: '1090 x 655',
      balstorlek: '1200 x 800 x 1000',
      kategori: ['Vertikala balpressar'],
      serie: 'X-serien',
      avfallstyp: ['Wellpapp', 'Plastfolie', 'Papper', 'PET flaskor', 'Aluminium burkar', 'Metall burkar', 'Hårdplast', 'Flytande', 'EPS Skumplast'],
      beskrivning: 'En kompakt och kraftfull balpress med galvaniserad yta. För smididg komprimering i en krävande miljö.',
      imageUrl: '/product-pictures/X30G/X30G.webp',
      thumbnails: ['/product-pictures/X30G/X30G_t1.PNG', '/product-pictures/X30G/X30G_t2.PNG', '/product-pictures/X30G/X30G_t3.PNG', '/product-pictures/X30G/X30G_t4.PNG',],
      dimensionsImg: '/brochures/X30G_size.svg',
      brochureUrl: '/brochures/PDF_BROCHURE_X30G.pdf',
      presstryck: '30',
      strömförsörjning: '3x400V 50Hz 16A',
      motorstorlek: '4.0',
      ljudnivå: '59-60',
      arbetscykeltid: '25',
      mått: '1760 x 1255 x 2295',
      vikt: '1710',
      fyllningshöjd: '1130',
      presskammarens_höjd: '1675',
      slaglängd: '940',
      antal_band: '4',
      bindningstyp: 'Balband / Stålband',
      ljus_vid_full_bal: 'Ja',
      utbalning: 'Automatisk',
      videoId: 'yjC1_Bpgq1Q?si=R9ZoZl0IcemXLmyN',
      användning_manual: {
        one: 'Fyll ditt plast- eller kartongavfall i balpressen.',
        two: 'En indikator visar dig när kammaren är full.',
        three: 'Slå av och skjut ut den färdiga balen.',
        four: 'Ta bort balen och lagra den tills insamlingen.'
      },
      höjdpunkter: {
        rubrik_ett: 'Passar till krävande miljöer',
        text_ett: 'Enligt standard DS/EN 1461:2009 är den galvaniserade beläggningen idealiskt i miljöer där maskinen utsätts för vatten eller andra typer av vätskor som till exempel rester från PET flaskor.',
        rubrik_lista: 'X30 galvaniserad - balpressen för utmanande mijöer:',
        lista: [
          'Het galvaniserad',
          'Lever upp till kraven enligt standard DS/EN 1461:2009',
          'Passar till PET flaskor, aluminium och andra matburkar',
          'Tillval för dina speciella behov',
          'Kombinera balnins och dränering i ett steg',
        ]
      }
  },
  {
      id: 'B30_Wide',
      namn: 'Balpress B30 Wide',
      typ: 'Balpressar',
      volym: 'Stor',
      balvikt: { cardboard: '400-500', plastic: '400-600' },
      fyllningsöppning: '1525 x 560',
      balstorlek: '1550 x 800 x 1100',
      kategori: ['Vertikala balpressar'],
      serie: 'B-serien',
      avfallstyp: ['Plastfolie', 'Wellpapp', 'Papper'],
      beskrivning: 'Önskar du en maskin med extra brett inkast, välj B30 Wide. Du får ett extra stort inkast och inget behov att vika eller ens större kartonger.',
      imageUrl: '/product-pictures/B30W/B30W.webp',
      thumbnails: ['/product-pictures/B30W/B30W_t1.PNG', '/product-pictures/B30W/B30W_t2.PNG', '/product-pictures/B30W/B30W_t3.PNG', '/product-pictures/B30W/B30W_t4.PNG',],
      dimensionsImg: '/brochures/B30W_size.svg',
      brochureUrl: '/brochures/PDF_BROCHURE_B30W.pdf',
      presstryck: '30',
      strömförsörjning: '3x400V 50Hz 16A',
      motorstorlek: '4.0',
      ljudnivå: '62-64',
      arbetscykeltid: '43',
      mått: '2080 x 1270 x 3350',
      vikt: '1800',
      fyllningshöjd: '1260',
      presskammarens_höjd: '1680',
      slaglängd: '1000',
      antal_band: '6',
      bindningstyp: 'Balband / Stålband',
      ljus_vid_full_bal: 'Ja',
      utbalning: 'Automatisk',
      videoId: 'yrkIB2YHl_8?si=C6PjbbwfSeY6Xl2o',
      användning_manual: {
        one: 'Fyll ditt plast- eller kartongavfall i balpressen.',
        two: 'En indikator visar dig när kammaren är full.',
        three: 'Slå av och skjut ut den färdiga balen.',
        four: 'Ta bort balen och lagra den tills insamlingen.'
      },
      höjdpunkter: {
        rubrik_ett: 'Balpress för extremt stora kartonger',
        text_ett: 'Tack vare ett extra brett inkast på mer än 1,5 meter hanterar denna maskin även de största kartongerna.',
        rubrik_två: 'Hög komprimerinsgrad',
        text_två: 'Trycket på balen behålls medans du knyter den. Detta gör att du få en extremt kompakt och stabil bal för din kartong eller plast.',
        rubrik_lista: 'B30 Wide - Extra stort inkast:',
        lista: [
          'Extra stor kammare (>1,5 meter)',
          'Även för de allra största kartongerna',
          'Använd plastband eller stålband',
          'Meddelande lampa när maskinen är full',
          'Automatisk urbalning',
          'Tillverkar stora wellpapps balar på minst 400 kilo',
          'Pulverlackering ger hållbar finish i många färger'
        ]
      }
  },
  {
      id: 'X40_Wide',
      namn: 'Balpress X40 Wide',
      typ: 'Balpressar',
      volym: 'Stor',
      balvikt: { cardboard: '375-550', plastic: '400-600' },
      fyllningsöppning: '1415 x 520',
      balstorlek: '1550 x 800 x 1100',
      kategori: ['Vertikala balpressar'],
      serie: 'X-serien',
      avfallstyp: ['Wellpapp', 'Plastfolie', 'Papper', 'PET flaskor', 'Aluminium burkar', 'Metall burkar', 'Hårdplast', 'Textilier', 'EPS Skumplast'],
      beskrivning: 'Med kraftiga korslagda cylindrar är X40 Wide det idealiska valet när du behöver en låg installatinoshöjd och balar som kan skickas direkt till återvinning.',
      imageUrl: '/product-pictures/X40W/X40W.webp',
      thumbnails: ['/product-pictures/X40W/X40W_t1.PNG', '/product-pictures/X40W/X40W_t2.PNG', '/product-pictures/X40W/X40W_t3.PNG', '/product-pictures/X40W/X40W_t4.PNG'],
      dimensionsImg: '/brochures/X40W_size.svg',
      brochureUrl: '/brochures/PDF_BROCHURE_X40W.pdf',
      presstryck: '40',
      strömförsörjning: '3x400V 50Hz 16A',
      motorstorlek: '4.0',
      ljudnivå: '59-60',
      arbetscykeltid: '40',
      mått: '2080 x 1255 x 2340',
      vikt: '2200',
      fyllningshöjd: '1175',
      presskammarens_höjd: '1540',
      slaglängd: '1090',
      antal_band: '6',
      bindningstyp: 'Balband / Stålband',
      ljus_vid_full_bal: 'Ja',
      utbalning: 'Automatisk',
      videoId: 'yjC1_Bpgq1Q?si=R9ZoZl0IcemXLmyN',
      användning_manual: {
        one: 'Fyll ditt plast- eller kartongavfall i balpressen.',
        two: 'En indikator visar dig när kammaren är full.',
        three: 'Slå av och skjut ut den färdiga balen.',
        four: 'Ta bort balen och lagra den tills insamlingen.'
      },
      höjdpunkter: {
        rubrik_ett: 'Lågbyggd design',
        text_ett: 'X40 Wide är en lågbyggd maskin som inter kräver mer än 2. 4 meter i takhöjd och ändå gör balar på 400kg.',
        rubrik_två: 'Många olika avfallsslag',
        text_två: 'Denna kraftfulla och lågbyggda balpress kan hantera olika typer av avfall så som, plastflaskor, aluminium burkar likväl som kartong och mjukplat.',
        rubrik_lista: 'X40 Wide - färdiga bruksbalar i olika avfallsslag',
        lista: [
          'Minst 400kg balar i wellpapp',
          'Mindre än 2,36 m i installationshöjd',
          'Utmärkt för wellpapp, mjukplast, PET flaskor, aluminium burkar osv.',
          'Låg ljudnivå - i likhet med en kontorsskrivare',
          'Pulverlackering ger hållbar finish i många färger'
        ]
      }
  },
  {
      id: 'B50',
      namn: 'Balpress B50',
      typ: 'Balpressar',
      volym: 'Stor',
      balvikt: { cardboard: '400-500', plastic: '450-550' },
      fyllningsöppning: '1200 x 565',
      balstorlek: '1200 x 800 x 1100',
      kategori: ['Vertikala balpressar'],
      serie: 'B-serien',
      avfallstyp: ['Wellpapp', 'Plastfolie', 'Papper'],
      beskrivning: 'En kraftfull balpress för alla typer av industrier. B50 har en hög presskraft och är tillverkad för effektiv avfallshanteirng av kartong och mjukplast.',
      imageUrl: '/product-pictures/B50/B50.webp',
      thumbnails: ['/product-pictures/B50/B50_t1.PNG', '/product-pictures/B50/B50_t2.PNG', '/product-pictures/B50/B50_t3.PNG', '/product-pictures/B50/B50_t4.PNG',],
      dimensionsImg: '/brochures/B50_size.svg',
      brochureUrl: '/brochures/PDF_BROCHURE_B50.pdf',
      presstryck: '50',
      strömförsörjning: '3x400V 50Hz 16A',
      motorstorlek: '4.0',
      ljudnivå: '62-64',
      arbetscykeltid: '55 / 26',
      mått: '1820 x 1320 x 3110',
      vikt: '1755',
      fyllningshöjd: '1165',
      presskammarens_höjd: '1600',
      slaglängd: '900',
      antal_band: '4',
      bindningstyp: 'Balband / Stålband',
      ljus_vid_full_bal: 'Ja',
      utbalning: 'Automatisk',
      videoId: 'yrkIB2YHl_8?si=C6PjbbwfSeY6Xl2o',
      användning_manual: {
        one: 'Fyll ditt plast- eller kartongavfall i balpressen.',
        two: 'En indikator visar dig när kammaren är full.',
        three: 'Slå av och skjut ut den färdiga balen.',
        four: 'Ta bort balen och lagra den tills insamlingen.'
      },
      höjdpunkter: {
        rubrik_ett: 'För optimal transport logistik',
        text_ett: 'B50 ger dig kompakta balar med hög densitet med idelaiska mått för optimal transportlogistik.',
        rubrik_två: 'Optimala pallstorlek på balarna',
        text_två: 'Balarna mäter 1200 x 800 x 1100 mm och passar på en EUR-pall.',
        rubrik_lista: 'B50 - kraftfull balpress med hög kapacitet:',
        lista: [
          'Tack vare maskinens design och möjligheten att sänka cylindrarna får man en låg transporthöjd',
          '16A standard säkring',
          'Kort presscykel',
          'Låg ljudnivå',
          'Hög komprimeringsgrad',
          'Gör stora balar (minst 400kg wellpapp)',
          'Pulverlackering ger hållbar finish i många färger'
        ]
      }
  },
  {
      id: 'X50',
      namn: 'Balpress X50',
      typ: 'Balpressar',
      volym: 'Stor',
      balvikt: { cardboard: '350-450', plastic: '400-500' },
      fyllningsöppning: '1090 x 610',
      balstorlek: '1200 x 800 x 1100',
      kategori: ['Vertikala balpressar'],
      serie: 'X-serien',
      avfallstyp: ['Wellpapp', 'Plastfolie', 'Papper', 'PET flaskor', 'Aluminium burkar', 'Metall burkar', 'Hårdplast', 'Textilier', 'EPS skumplast'],
      beskrivning: 'X50 är en högkvalitets maskin designad med fokus på tilförlitlighet och överlägsen presskraft. Med denna maskin får du en säker och funktionell arbetsmiljö.',
      imageUrl: '/product-pictures/X50/X50.webp',
      thumbnails: ['/product-pictures/X50/X50_t1.PNG', '/product-pictures/X50/X50_t2.PNG', '/product-pictures/X50/X50_t3.PNG', '/product-pictures/X50/X50_t4.PNG'],
      dimensionsImg: '/brochures/X50_size.svg',
      brochureUrl: '/brochures/PDF_BROCHURE_X50.pdf',
      presstryck: '50',
      strömförsörjning: '3x400V 50Hz 16A',
      motorstorlek: '5.5',
      ljudnivå: '62-64',
      arbetscykeltid: '22',
      mått: '1965 x 1365 x 2540',
      vikt: '2435',
      fyllningshöjd: '1210',
      presskammarens_höjd: '1710',
      slaglängd: '1210',
      antal_band: '4',
      bindningstyp: 'Balband / Stålband',
      ljus_vid_full_bal: 'Ja',
      utbalning: 'Automatisk',
      videoId: 'yjC1_Bpgq1Q?si=R9ZoZl0IcemXLmyN',
      användning_manual: {
        one: 'Fyll ditt plast- eller kartongavfall i balpressen.',
        two: 'En indikator visar dig när kammaren är full.',
        three: 'Slå av och skjut ut den färdiga balen.',
        four: 'Ta bort balen och lagra den tills insamlingen.'
      },
      höjdpunkter: {
        rubrik_ett: 'Balpress för kraftfull komprimering',
        text_ett: 'Effektiv och tidsbesparande hantering av industriavfall. Denna maskin hanterar med lätthet wellpapp, papper, hårdplast, mjukplast, aluminium och plåtbutrkar.',
        rubrik_två: 'Stor balproduktion och hög tillförlitlighet.',
        text_två: 'Med denna maskin får du stora balar. Tack vare den unika hydrauliken får maskinen en hög kapacitet och lång livscykel. Den unika desingen ger enkel åtgång till elektriska delar och snabb service.',
        rubrik_lista: 'X50 - Kraftfull industri balpress:',
        lista: [
          'Effektiv komprimering av de flesta avfallsslag',
          'Hög kompressionsgrad - även på expanderande material',
          'Minimal materialexpansion mellan presscyklerna',
          'Flexibel bindning av bal med antingen plastband eller stålband',
          'Kort cykeltid',
          'Smidig åtkomst till elektriska delar för service och underhåll',
          'Rörliga delar sitter väl skyddade',
          'Pulverlackering ger hållbar finish i många färger'
        ]
      }
  },
  {
      id: 'X50_Galvaniserad',
      namn: 'Balpress X50 Galvaniserad',
      typ: 'Balpressar',
      volym: 'Stor',
      balvikt: { cardboard: '350-450', plastic: '400-500' },
      fyllningsöppning: '1090 x 610',
      balstorlek: '1200 x 800 x 1100',
      kategori: ['Vertikala balpressar'],
      serie: 'X-serien',
      avfallstyp: ['Wellpapp', 'Plastfolie', 'Papper', 'PET flaskor', 'Aluminium burkar', 'Metall burkar', 'Hårdplast', 'Flytande', 'EPS skumplast'],
      beskrivning: 'Den galvaniserade X50 modellen passar för kraftfull komprimering i en krävande och blöt miljö.',
      imageUrl: '/product-pictures/X50G/X50G.webp',
      thumbnails: ['/product-pictures/X50G/X50G_t1.PNG', '/product-pictures/X50G/X50G_t2.PNG', '/product-pictures/X50G/X50G_t3.PNG', '/product-pictures/X50G/X50G_t4.PNG'],
      dimensionsImg: '/brochures/X50G_size.svg',
      brochureUrl: '/brochures/PDF_BROCHURE_X50G.pdf',
      presstryck: '50',
      strömförsörjning: '3x400V 50Hz 16A',
      motorstorlek: '5.5',
      ljudnivå: '62-64',
      arbetscykeltid: '22',
      mått: '1965 x 1365 x 2540',
      vikt: '2435',
      fyllningshöjd: '1210',
      presskammarens_höjd: '1710',
      slaglängd: '1210',
      antal_band: '4',
      bindningstyp: 'Balband / Stålband',
      ljus_vid_full_bal: 'Ja',
      utbalning: 'Automatisk',
      videoId: 'yjC1_Bpgq1Q?si=R9ZoZl0IcemXLmyN',
      användning_manual: {
        one: 'Fyll ditt plast- eller kartongavfall i balpressen.',
        two: 'En indikator visar dig när kammaren är full.',
        three: 'Slå av och skjut ut den färdiga balen.',
        four: 'Ta bort balen och lagra den tills insamlingen.'
      },
      höjdpunkter: {
        rubrik_ett: 'För blöta miljöer',
        text_ett: 'För installationer som inte kräver rostfritt, men där kraven på ytbehandling är så svåra att en normalt målad balpress skulle ha en avsevärt kortare livstid.',
        rubrik_lista: 'X50 Galvaniserad - balpress för en blöt och krävande miljö:',
        lista: [
          'DS/EN 1461:2009 galvaniserad',
          'För alla typer av återvinningsmaterial',
          'Passar bra till PET flaskor, aluminium och matburkar samt plastmuggar',
          'Möjlighet att kombinera både balning och dränering av överbliven vätska samtidigt'
        ]
      }
  },
  {
      id: 'B50_XL_SD',
      namn: 'Balpress B50 XL SD',
      typ: 'Balpressar',
      volym: 'Stor',
      balvikt: { cardboard: '650-800', plastic: '650-800' },
      fyllningsöppning: '1825 x 670',
      balstorlek: '1850 x 1100 x 1200-1300',
      kategori: ['Vertikala balpressar'],
      serie: 'B-serien',
      avfallstyp: ['Wellpapp', 'Plastfolie', 'Papper'],
      beskrivning: 'En balpress med extra brett inkast och djup kammare på 182 x 110 cm. Balstorleken är optimerad för lastbil. Du kan stapla balarna två och två.',
      imageUrl: '/product-pictures/B50XL/B50XL.webp',
      thumbnails: ['/product-pictures/B50XL/B50XL_t1.PNG', '/product-pictures/B50XL/B50XL_t2.PNG', '/product-pictures/B50XL/B50XL_t3.PNG', '/product-pictures/B50XL/B50XL_t4.PNG',],
      dimensionsImg: '/brochures/B50XL_size.svg',
      brochureUrl: '/brochures/PDF_BROCHURE_B50XL.pdf',
      presstryck: '50',
      strömförsörjning: '3x400V 50Hz 16A',
      motorstorlek: '5.5',
      ljudnivå: '62-64',
      arbetscykeltid: '26',
      mått: '2495 x 1660 x 3590 / 2540',
      vikt: '3250',
      fyllningshöjd: '1320',
      presskammarens_höjd: '1830',
      slaglängd: '1100',
      antal_band: '8',
      bindningstyp: 'Balband / Stålband',
      ljus_vid_full_bal: 'Ja',
      utbalning: 'Automatisk',
      videoId: 'yrkIB2YHl_8?si=C6PjbbwfSeY6Xl2o',
      användning_manual: {
        one: 'Fyll ditt plast- eller kartongavfall i balpressen.',
        two: 'En indikator visar dig när kammaren är full.',
        three: 'Slå av och skjut ut den färdiga balen.',
        four: 'Ta bort balen och lagra den tills insamlingen.'
      },
      höjdpunkter: {
        rubrik_ett: 'Effektiv och snabb avfallshantering',
        text_ett: 'Idealisk för stora mängder avfall. Tack vare den breda jalusiluckan på 1,82 m och den djupa kammaren på 1,1 m behöver du aldrig skära eller vika kartongerna innan du slänger in dem.',
        rubrik_två: 'Optimerad för lastbil',
        text_två: 'De kompimerade balarna är optimerad för transport med lastbil cå du kan stapla balarna två och två.',
        rubrik_lista: 'B50 XL SD - balar optimerade för lastbilstransport:',
        lista: [
          'Extra stor kammare',
          'Knyts med 8 band för extra stabilitet',
          'Jalusidörr som öppnar automatisk som standard',
          'Balar optimerade för lastbilstransport',
          'Komprimerade balar som mäter 1825 x 1100 x 1200-1300 mm',
          'Stapla balarna två och två istället för 3 för att optimera lastbilstransporten',
          'Pulverlackering ger hållbar finish i många färger'
        ]
      }
  },
  {
      id: 'CC',
      namn: 'Kanalpressar CC',
      typ: 'Balpressar',
      volym: 'Mellan / Stor',
      balvikt: { cardboard: '300-600', plastic: '420-450' },
      balstorlek: '750-2850 x 750-1100 x 700-750',
      kategori: ['Horisontella balpressar'],
      serie: 'Horisontella balpressar CC',
      avfallstyp: ['Wellpapp', 'Plastfolie'],
      beskrivning: 'En helautomatisk men kompakt avfallslösning med hög kapacitet. Dra nytta av fördelarna med konstant fyllning, automatisk presscykel och automatisk bindning.',
      imageUrl: '/product-pictures/CC/CC.PNG',
      thumbnails: ['/product-pictures/CC/CC_t1.PNG', '/product-pictures/CC/CC_t2.PNG', '/product-pictures/CC/CC_t3.PNG', '/product-pictures/CC/CC_t4.PNG', '/product-pictures/CC/CC_t5.PNG'],
      dimensionsImg: ['/brochures/PDF_DIMENSION_CC20.pdf', '/brochures/PDF_DIMENSION_CC30.pdf', '/brochures/PDF_DIMENSION_CC40.pdf'],
      brochureUrl: ['/brochures/PDF_BROCHURE_CC.pdf', '/brochures/PDF_BROCHURE_CC.pdf', '/brochures/PDF_BROCHURE_CC.pdf'],
      presstryck: 'Varierar beroende på modell',
      strömförsörjning: 'Varierar beroende på modell',
      bindningstyp: 'Automatisk',
      utbalning: 'Automatisk',
      modeller: {
        CC20: [
          'CC20',
          'En mindre, men effektiv horisontell balpress, som är lämplig för kartong, papper och plastfilm.',
          'Balarna binds automatiskt',
          'Kräver inte mycket plats',
          'Låg ljudnivå'
        ],
        CC30: [
          'CC30',
          'Kompakt horisontell press som producerar balar med hög densitet av t.ex. kartong, papper och plastfilm.',
          'Kräver begränsat utrymme',
          'Avfall fylls kontinuerligt',
          'Justerbar balstorlek (i längd)'
        ],
        CC40: [
          'CC40',
          'Fullautomatisk horisontell press med hög kapacitet. För större mängder av återvinningsavfall som komprimeras till stapelbara balar.',
          'Olika fyllningsmöjligheter',
          'Stor fyllningsöppning',
          'Enkel hantering'
        ],
      },
      modeller_bilder: ['/product-pictures/CC/CC20.PNG', '/product-pictures/CC/CC30.PNG', '/product-pictures/CC/CC40.PNG',],
      höjdpunkter: {
        rubrik_ett: 'Flexibel lösning på begränsat område',
        text_ett: 'Den kompakta designen och de många möjligheterna för olika fyllningsmetoder ger stor flexibilitet i installationen av denna lösning.',
        rubrik_två: 'Automatisk lösning för alla behov',
        text_två: 'Med det breda sortimentet bland CC-modellerna som utgångspunkt kan vi erbjuda att anpassa lösningen till just dina avfallstyper och mängder.',
        rubrik_lista: 'Kompakt lösning - begränsat platsbehov:',
        lista: [
          'Kontinuerlig fyllning',
          'Balarna binds automatiskt',
          'Låg ljudnivå',
          'Justerbar balstorlek (i längd)',
          'Extra bred fyllningsöppning'
        ]
      }
  },
  {
      id: 'DP16',
      namn: 'Fatpress DP16',
      typ: 'Övrigt',
      volym: 'Liten / Mellan / Stor',
      balvikt: { steel_drums: '200-220' },
      fyllningsöppning: '600 x 950',
      kategori: ['Annan utrustning'],
      serie: 'Fatpressar',
      avfallstyp: ['Trummor'],
      beskrivning: 'Fatpressen DP16 är framtagen för att komprimera oljefat och andra fat utan explosivt innehåll. Den är användbar för fat på upp till 200 liter (58 x 58 cm).',
      imageUrl: '/product-pictures/DP16/DP16.png',
      thumbnails: ['/product-pictures/DP16/DP16_t1.PNG', '/product-pictures/DP16/DP16_t2.PNG', '/product-pictures/DP16/DP16_t3.PNG', '/product-pictures/DP16/DP16_t4.PNG'],
      dimensionsImg: '/brochures/DP16_size.svg',
      brochureUrl: '/brochures/PDF_BROCHURE_DP16.pdf',
      presstryck: '16',
      strömförsörjning: '3x400V 50Hz 16A',
      motorstorlek: '4.0',
      ljudnivå: '62-64',
      arbetscykeltid: '45',
      mått: '1305 x 1005 x 2910',
      vikt: '880',
      fyllningshöjd: '200',
      presskammarens_höjd: '990',
      slaglängd: '985',
      användning_manual: {
        one: 'Fyll ditt avfall i fatpressen.',
        two: 'En indikator visar dig när kammaren är full.',
        three: 'Slå av och skjut ut den färdiga balen.',
        four: 'Ta bort balen och lagra den tills insamlingen.'
      },
      höjdpunkter: {
        rubrik_ett: 'Komprimerar fat upp till 90%',
        text_ett: 'Komprimerar enkelt era stål- och oljefat. Fatpressen komprimerar faten med upp till 90%.',
        rubrik_två: 'För fat upp till 200 liter',
        text_två: 'Fatpressen komprimerar enkelt fat utan explosivt innehåll på upp till 200 liter.',
        rubrik_lista: 'DP16 - komprimerar fat på upp till 200 lter:',
        lista: [
          'Upp till 90% komprimering',
          'Taggar i pressplattan punkterar fatet och tar bort luften',
          'Hög nivå på säkerhet',
          'Säkerhetsbrytare gör så att maskinen inte fungerar utan att dörren är stängd',
          'Komprimerade fat är enklare att stapla och lagra'
        ]
      }
  },
  {
      id: 'EPS_Granulator',
      namn: 'EPS Granulator',
      typ: 'Övrigt',
      volym: 'Liten / Mellan',
      fyllningsöppning: '548 x 552',
      kategori: ['Annan utrustning'],
      serie: 'Annan utrustning',
      avfallstyp: ['EPS Skumplast'],
      beskrivning: 'EPS granulatorn är designad för granulering av EPS material och reducerar volymen 3:1. Utmärkt för industri företag och detaljhandel som hanterar stora volymer EPS.',
      imageUrl: '/product-pictures/EPS/EPS.png',
      thumbnails: ['/product-pictures/EPS/EPS_t1.PNG', '/product-pictures/EPS/EPS_t2.PNG', '/product-pictures/EPS/EPS_t3.PNG', '/product-pictures/EPS/EPS_t4.PNG'],
      dimensionsImg: '/brochures/EPS_size.svg',
      brochureUrl: '/brochures/PDF_BROCHURE_EPS.pdf',
      strömförsörjning: '3x400V 50Hz 16A',
      motorstorlek: '1.5',
      ljudnivå: '70',
      mått: '1000 x 1400 x 2120',
      vikt: '235',
      fyllningshöjd: '1790',
      compactionRatio: '1899-12-31',
      videoId: 'rDsxWmfSUWc?si=7LRfiRIvqtf6bj56',
      användning_manual: {
        one: 'Fyll EPS-material i granulatorn.',
        two: 'Maskinen granulerar materialet till små bitar.',
        three: 'När säcken är full, byt ut den till en ny.',
        four: 'Lagrat granulat kan återvinnas.'
      },
      höjdpunkter: {
        rubrik_ett: 'Reducerar volymen upp till tre gånger',
        text_ett: 'Granulatorn reducerar ditt skrymmande EPS material med upp till tre gånger genom att kvarna det till små granulater.',
        rubrik_två: 'För utmannande miljöer och fuktiga utrymmen',
        text_två: 'Denna granulator är mycket lämplig för miljöer där det inte finns särskilda hygienkrav för rostfritt stål, men där kraven på maskinens yta är tuffa att en standard lackerad yta inte är tillräckliga.',
        rubrik_lista: 'EPS Granulator Galvaniserad - minskar volymen av skrymmande EPS:',
        lista: [
          'Justerbar kapacitet beroende på individuella behov',
          'Plastsäck som samlar upp granulaten',
          'Lätt att byta säck när den är full',
          'Tar liten plats',
          'Låg totalhöjd',
          'Galvaniserad yta'
        ]
      }
  },
  {
      id: 'CT',
      namn: 'Tippsystem CT',
      typ: 'Övrigt',
      kategori: ['Annan utrustning'],
      serie: 'Annan utrustning',
      avfallstyp: ['SAKNAS'],
      beskrivning: 'Kärlvändaren kan användas till olika kärlstorleka för att tömma ditt avfall direkt in den komprimator eller på transportband. Systemet kan anpassas till olika lyfthöjder. Välj antingen en stationär modell med fötter eller en mobil med hjul som du kan flytta runt.',
      imageUrl: '/product-pictures/CT/CT.png',
      thumbnails: ['/product-pictures/CT/CT_t1.PNG', '/product-pictures/CT/CT_t2.PNG', '/product-pictures/CT/CT_t3.PNG', '/product-pictures/CT/CT_t4.PNG',],
      dimensionsImg: '/brochures/CT_size.svg',
      brochureUrl: '/brochures/PDF_BROCHURE_CT.pdf',
      innehåll: '6-24',
      fyllningsöppning: '1090 x 530',
      balstorlek: '1200 x 800 x 800',
      presstryck: '25-30',
      strömförsörjning: '1x230V 50Hz 10A',
      motorstorlek: '1.1',
      ljudnivå: '65-68',
      arbetscykeltid: '57-75',
      mått: '2100 x 2510 x 4600',
      vikt: '1000',
      avlastningshöjd: '1670-2570',
      lastkapacitet: '400',
      videoId: 'njNxBFm6ZJo?si=mwFEyNVWXButbq7U',
      användning_manual: {
        one: 'Placera kärlet i lyftutrymmet.',
        two: 'Använd tvåhandsfattningen för att tippa soptunnan eller behållaren.',
        three: 'Ta bort den tomma behållaren.',
        four: 'Lagra de tomma kärlen för återanvändning.'
      },
      höjdpunkter: {
        rubrik_ett: 'Effektiv tömning av kärl',
        text_ett: 'Flexibla tömningslösningar för plastkärl.',
        rubrik_två: 'Individuella lösningar',
        text_två: 'Med tre olika modeller och olika tillval så kan du alltid få en tipplösning som passar ditt specifika behov.',
        rubrik_lista: 'Lyft system - för tömning av kärl:',
        lista: [
          'För standardkärl',
          'Fasta eller rörliga hjul eller fasta ben',
          'Lyfthöjd mellan 1600 - 2500 mm',
          'För kärl upp till 1100 L',
          'Manöverpanel kan sättas antingen på höger eller vänster sida',
          'Hållbar design - Kraftfull stålkonstruktion',
          'Hydrauliska cylindrar'
        ]
      }
  },
  {
    id: 'HG-HGS-HA-HAN',
    namn: 'Mobilkomprimatorer HG-HGS-HA-HAN',
    typ: 'Komprimatorer',
    kategori: ['Mobila komprimatorer'],
    serie: 'Mobila komprimatorer',
    volym: 'Mellan',
    avfallstyp: ['Wellpapp', 'Plastfolie', 'Papper', 'Hårdplast', 'Blandat'],
    beskrivning: 'En mobil komprimator är en flexibel avfallslösning. Den är optimal för en lång rad avfallsfraktioner som t.ex. kartong, folie och brännbart avfall.',
    imageUrl: '/product-pictures/H/HA.jpg',
    thumbnails: ['/product-pictures/H/H_t1.JPG', '/product-pictures/H/H_t2.jpg', '/product-pictures/H/H_t3.jpg', '/product-pictures/H/H_t4.jpg'],
    innehåll: '6-24',
    brochureUrl: ['/brochures/PDF_BROCHURE_HG-HGS.pdf', '/brochures/PDF_BROCHURE_HG-HGS.pdf', '/brochures/PDF_BROCHURE_HA-HAN.pdf', '/brochures/PDF_BROCHURE_HA-HAN.pdf'],
    videoId: 'J3JH3laLHCY?si=pMwBIuxFyK66AedG',
    modeller: {
      HG: [
        'HG',
        'Utrustas med krok och/eller vajeruppdrag',
        'Kort påfyllningskammare',
        '16-24 m³ container volym',
      ],
      HGS: [
        'HGS',
        'Utrustas med krok och/eller vajeruppdrag',
        'Stor påfyllningskammare',
        '16-24 m³ container volym',
      ],
      HA: [
        'HA',
        'Utrustas med krok och/eller vajeruppdrag',
        'Stor påfyllningskammare',
        '6-12 m³ container volym',
      ],
      HAN: [
        'HAN',
        'Utrustas med krok och/eller vajeruppdrag',
        'Kort påfyllningskammare',
        '6-12 m³ container volym',
      ],
    },
    BRAin: {
      Rubrik: 'BRA-IN Control Unit ger optimal användning',
      Text: 'Alla nya maskiner levereras som standard med BRA-IN styr- och kommunikationsenhet. Den säkerställer minimal nedtid och utnyttjar din komprimators kapacitet på bästa möjliga sätt, med lägsta möjliga energiförbrukning. Du kan ställa in automatiska hämtningar beroende på fyllnadsnivå och få fjärrsupport, och samtidigt få tillgång till vår BRA-IN-webbportal.',
      Procent: [
        '25%',
        '15%',
        '15%',
      ],
      Fördel: [
        'Minskad energiförbrukning',
        'Mer avfall per tömning',
        'Mindre transportkostnader*',
      ],
      Asterix: '*Dessa siffror är baserade på konkreta fall. Beräknas utifrån den specifika situationen. SIM-abonnemang krävs.'
    },
    modeller_bilder: ['/product-pictures/H/HG.jpg', '/product-pictures/H/HGS.jpg', '/product-pictures/H/HA.jpg', '/product-pictures/H/HAN.jpeg'],
    användning_manual: {
      one: 'Lägg ditt avfall i maskinens presskammare.',
      two: 'En horisontell platta pressar avfallet in i maskinen.',
      three: 'Fortsätt fylla på tills maskinen indikerar att den är full.',
      four: 'Din renhållningsarbetare hämtar hela maskinen och kör den för tömning.',
      five: 'Den tömda komprimatorn returneras, och du kan fortsätta att fylla på igen.'
    },
    höjdpunkter: {
      rubrik_ett: 'Container med integrerad pressenhet',
      text_ett: 'En flexibel lösning som kan anpassas till ditt företags avfallshantering. Allt du behöver är ett korrekt dimensionerat eluttag – så är du igång.',
      rubrik_två: 'En mobil komprimator för många avfallsfraktioner',
      text_två: 'Denna maskin är lämplig för många avfallsfraktioner såsom kartong, papper, folie, plast, storsäckar och blandat brännbart avfall. När komprimatorn indikerar att den är full hämtas hela enheten av renhållningsföretaget och körs för tömning.',
      rubrik_lista: 'HG-HGS-HA-HAN - flexibla mobila komprimatorer:',
      lista: [
        'Inget behov av specialinstallation',
        'Intelligent styrning minimerar maskinens underhåll och optimerar maskinens användning',
        'Enkelt och överskådligt kontrollpanel',
        'Låg ljudnivå (<70 dB)',
        'Konisk designad container, säkerställer enkel tömning',
        'Många anpassningsmöjligheter',
        'Möjlighet till modem som kommunicerar med maskinen vid exempelvis fullmeddelanden, driftsoptimering m.m.'
      ]
    },
  },
  {
    id: 'PHR-PSR',
    namn: 'Mobilkomprimatorer PHR-PSR',
    typ: 'Komprimatorer',
    kategori: ['Mobila komprimatorer'],
    serie: 'Mobila komprimatorer',
    volym: 'Mellan',
    avfallstyp: ['Organiskt', 'Flytande'],
    beskrivning: 'Självrengörande mobilkomprimator för organiskt avfall. Extra tätning säkerställer mot läckage. Den levereras vid behov i flera varianter.',
    imageUrl: '/product-pictures/P/PSR.jpg',
    thumbnails: ['/product-pictures/P/P_t1.jpg', '/product-pictures/P/P_t2.jpg', '/product-pictures/P/P_t3.jpg', '/product-pictures/P/P_t4.jpg', '/product-pictures/P/PHR.jpg'],
    innehåll: '8-20',
    brochureUrl: ['/brochures/PDF_BROCHURE_PHR-PSR.pdf', '/brochures/PDF_BROCHURE_PHR-PSR.pdf'],
    videoId: 'J3JH3laLHCY?si=pMwBIuxFyK66AedG',
    modeller: {
      PHR: [
        'PHR',
        'Roll on / roll off komprimator',
        '16-20 m³ volym',
      ],
      PSR: [
        'PSR',
        'Roll on / roll off komprimator',
        '8-10 m³ volym',
      ],
    },
    BRAin: {
      Rubrik: 'BRA-IN Control Unit ger optimal användning',
      Text: 'Alla nya maskiner levereras som standard med BRA-IN styr- och kommunikationsenhet. Den säkerställer minimal nedtid och utnyttjar din komprimators kapacitet på bästa möjliga sätt, med lägsta möjliga energiförbrukning. Du kan ställa in automatiska hämtningar beroende på fyllnadsnivå och få fjärrsupport, och samtidigt få tillgång till vår BRA-IN-webbportal.',
      Procent: [
        '25%',
        '15%',
        '15%',
      ],
      Fördel: [
        'Minskad energiförbrukning',
        'Mer avfall per tömning',
        'Mindre transportkostnader*',
      ],
      Asterix: '*Dessa siffror är baserade på konkreta fall. Beräknas utifrån den specifika situationen. SIM-abonnemang krävs.'
    },
    modeller_bilder: ['/product-pictures/P/PHR.jpg', '/product-pictures/P/PSR.jpg'],
    användning_manual: {
      one: 'Lägg ditt avfall i maskinens presskammare.',
      two: 'En horisontell platta pressar avfallet in i maskinen.',
      three: 'Fortsätt fylla på tills maskinen indikerar att den är full.',
      four: 'Din renhållningsarbetare hämtar hela maskinen och kör den för tömning.',
      five: 'Den tömda komprimatorn returneras, och du kan fortsätta att fylla på igen.'
    },
    höjdpunkter: {
      rubrik_ett: 'Självrengörande pendlande pressplatta',
      text_ett: 'Vätskebaserat avfall och avfall som orsakar luktproblem hanteras enkelt i denna självrengörande mobilkomprimator. Försegling av tömningsdörren och den vätsketäta konstruktionen av fyllningskammaren förhindrar att mobilkomprimatorn läcker vätskor.',
      rubrik_två: 'Minimal rengöring',
      text_två: 'Den pendlande pressplattan är självrengörande, vilket gör att extra rengöring inte behövs. Teknikrummet är avskilt från avfallet, vilket ökar tillgängligheten och säkerheten vid service.',
      rubrik_lista: 'PHR-PSR - Mobila komprimatorer för vått avfall:',
      lista: [
        'Pendlande självrengörande pressplatta för vått avfall',
        'Intelligent styrning ger maskinen optimala driftförhållanden',
        'Designet förhindrar vätskeläckage',
        'Minskar luktproblem',
        'Låg inmatningshöjd',
        'Enkel installation - enkelt styrpanel',
        'Avfall separerat från elektriska komponenter',
        'Valfritt ozonanläggning för ytterligare minskning av luktproblem'
      ]
    },
  },
  {
    id: 'SHD-RHD-SAN',
    namn: 'Stationära komprimatorer SHD-RHD-SAN',
    typ: 'Komprimatorer',
    kategori: ['Stationära komprimatorer'],
    serie: 'Stationära komprimatorer',
    volym: 'Mellan / Stor',
    avfallstyp: ['Papper', 'Plastfolie', 'Wellpapp', 'Hårdplast', 'Blandat'],
    beskrivning: 'Stationära komprimatorlösningar är lämpliga för insamling av stora mängder avfall. Med separationen av pressenhet och container får du en högre komprimeringsgrad. Detta innebär att en större volym avfall kan transporteras bort.',
    imageUrl: '/product-pictures/S/SHD.jpeg',
    thumbnails: ['/product-pictures/S/S_t1.JPG', '/product-pictures/S/S_t2.JPG', '/product-pictures/S/S_t3.JPG', '/product-pictures/S/S_t4.JPG', '/product-pictures/S/S_t5.JPG'],
    fyllningsöppning: '900-2760 x 1460-1860',
    brochureUrl: ['/brochures/PDF_BROCHURE_SHD.pdf', '/brochures/PDF_BROCHURE_RHD-SAN.pdf', '/brochures/PDF_BROCHURE_RHD-SAN.pdf'],
    videoId: 'rVLl2eRpdVM?si=cgDsxXN0A9HMTyPD',
    modeller: {
      SHD: [
        'SHD',
        'Lång design - 3400-7180 mm (utan container)',
        'Presstryck 30-40 ton',
        'Containervolym 16-30 m³'
      ],
      RHD: [
        'RHD',
        'Kort design - 1970-3200 mm (utan container)',
        'Presstryck 30-40 ton',
        'Containervolym 16-30 m³'
      ],
      SAN: [
        'SAN',
        'Kort design - 2520 mm (utan container)',
        'Presstryck 29 ton',
        'Containervolym 16-30 m³'
      ],
    },
    modeller_bilder: ['/product-pictures/S/SHD.jpeg', '/product-pictures/S/RHD.jpeg', '/product-pictures/S/SAN.jpeg',],
    användning_manual: {
      one: 'Fyll ditt avfall i maskinen.',
      two: 'Den horisontella pressplattan komprimerar ditt avfall inne i containern.',
      three: 'Du får ett meddelande från maskinen när den är full.',
      four: 'Din renhållningsentreprenör hämtar containernheten.',
      five: 'Den tomma containern returneras och sätts på plats.'
    },
    höjdpunkter: {
      rubrik_ett: 'Stationär komprimator - maximal containervolym',
      text_ett: 'En stationär komprimatorlösning består av en pressenhet och en container, som är två separata enheter. Vid full indikation är det endast containern som transporteras bort för tömning. Kapaciteten ökar med upp till 50 % jämfört med en mobil komprimatorlösning.',
      rubrik_två: 'En lösning för alla avfallsbehov',
      text_två: 'En stationär komprimatorlösning behöver inte ta mycket plats. Modellerna RHD och SAN är mindre modeller som effektivt hanterar stora mängder avfall. Sättet att fylla avfall i maskinen anpassas efter fraktion och behov.',
      rubrik_lista: 'SHD-RHD-SAN - Stationära komprimatorer för många avfallstyper:',
      lista: [
        'Pressenhet och container är separata enheter',
        'Upp till 50 % högre kapacitet jämfört med en mobil komprimator',
        'Renhållningsentreprenören tar endast bort containern vid tömning',
        'Stor inmatningsöppning, säkerställer enkel avfallshantering',
        'Kort cykeltid - ingen väntetid',
        'Optimal komprimering med maximal presskraft',
        'Koniskt utformade containrar säkerställer enkel tömning',
        'Kundanpassade lösningar'
      ]
    },
  },
  {
    id: 'Megapress',
    namn: 'Mobilkomprimator Megapress',
    typ: 'Komprimatorer',
    kategori: ['Mobila komprimatorer'],
    serie: 'Mobila komprimatorer',
    volym: 'Mellan',
    avfallstyp: ['Papper', 'Plastfolie', 'Wellpapp', 'Hårdplast', 'Blandat', 'Trälådor', 'EPS Skumplast'],
    beskrivning: 'Mobilkomprimatorn Megapress har en extraordinärt stor skuff för att fylla olika avfallsfraktioner. Den breda och låga inmatningsöppningen gör det mycket enkelt och bekvämt att slänga även mycket stora kartonger i skuffen.',
    imageUrl: '/product-pictures/Megapress/Megapress.jpeg',
    thumbnails: ['/product-pictures/Megapress/Megapress.jpeg', '/product-pictures/Megapress/Megapress_t1.jpeg', '/product-pictures/Megapress/Megapress_t2.jpeg', '/product-pictures/Megapress/Megapress_t3.jpeg'],
    innehåll: '26',
    presstryck: '50',
    brochureUrl: '/brochures/PDF_BROCHURE_MEGAPRESS.pdf',
    BRAin: {
      Rubrik: 'BRA-IN Control Unit ger optimal användning',
      Text: 'Alla nya maskiner levereras som standard med BRA-IN styr- och kommunikationsenhet. Den säkerställer minimal nedtid och utnyttjar din komprimators kapacitet på bästa möjliga sätt, med lägsta möjliga energiförbrukning. Du kan ställa in automatiska hämtningar beroende på fyllnadsnivå och få fjärrsupport, och samtidigt få tillgång till vår BRA-IN-webbportal.',
      Procent: [
        '25%',
        '15%',
        '15%',
      ],
      Fördel: [
        'Minskad energiförbrukning',
        'Mer avfall per tömning',
        'Mindre transportkostnader*',
      ],
      Asterix: '*Dessa siffror är baserade på konkreta fall. Beräknas utifrån den specifika situationen. SIM-abonnemang krävs.'
    },
    användning_manual: {
      one: 'Fyll avfall i kammaren.',
      two: 'En horisontell pressplatta komprimerar ditt avfallsmaterial.',
      three: 'Fortsätt komprimera tills fullindikatorn visar att komprimatorn är full.',
      four: 'Din renhållningsentreprenör eller transportör hämtar hela enheten.',
      five: 'Den tomma mobila komprimatorn sätts tillbaka på plats.'
    },
    höjdpunkter: {
      rubrik_ett: 'Mobilkomprimator med stor skuff och kraftfull komprimering',
      text_ett: 'Megapress är en mobil komprimator där komprimatorenheten är fast ansluten till containern. Det innebär att hela maskinen hämtas för tömning.',
      rubrik_två: '',
      text_två: 'Med den breda och låga skuffen är den idealisk för komprimering av stora mängder återvinningsbara material som kartong, möbler, pallar och annat torrt avfall. Den lågt placerade, stora skuffen gör det enkelt att fylla mycket stora kartonger och andra stora fraktioner.',
      rubrik_lista: 'Megapress - mobil komprimator med extra stor inmatningsöppning:',
      lista: [
        'Bred skuff med extra låg inmatningsöppning (1100 mm)',
        'Fantastisk för stort avfall som möbler, pallar, trä och kartong',
        'Flytande tak för enkel tömning',
        'Hardox-förstärkning som standard',
        'Extra starka sidoväggar på skuffen',
        'Två stora dubbla serviceluckor med enkel åtkomst',
      ]
    },
  },
  {
    id: 'SP-SPN',
    namn: 'Stationära skruvkomprimatorer SP-SPN',
    typ: 'Komprimatorer',
    kategori: ['Stationära skruvkomprimatorer'],
    serie: 'Stationära skruvkomprimatorer',
    volym: 'Stor',
    avfallstyp: ['Papper', 'PET flaskor', 'Wellpapp', 'Trälådor'],
    beskrivning: 'Stationära skruvkomprimatorlösningar är lämpliga för insamling av stora mängder avfall. På grund av den separata pressenheten och containern uppnår du en högre densitet när containern ska tömmas.',
    imageUrl: '/product-pictures/SP/SP.jpeg',
    thumbnails: ['/product-pictures/SP/SP_t1.jpeg', '/product-pictures/SP/SP_t2.jpeg', '/product-pictures/SP/SP_t3.jpeg', '/product-pictures/SP/SP_t4.jpeg', '/product-pictures/SP/SP_t5.jpeg', '/product-pictures/SP/SP_t6.jpeg'],
    innehåll: '16-30',
    brochureUrl: ['/brochures/PDF_BROCHURE_SP-SPN.pdf', '/brochures/PDF_BROCHURE_SP-SPN.pdf'],
    videoId: 'giK6FxzujaY?si=eAN4KF6630MFa2Ug',
    modeller: {
      SP: [
        'SP',
        'Längd utan container: 1335 mm',
        'Vridmoment: 7484 Nm',
        'Motoreffekt: 11 kW'
      ],
      SPN: [
        'SPN',
        'Längd utan container: 1510 mm',
        'Vridmoment: 7004-9550 Nm',
        'Motoreffekt: 11-15 kW'
      ]
    },
    modeller_bilder: ['/product-pictures/SP/SP.jpeg', '/product-pictures/SP/SPN.jpeg'],
    användning_manual: {
      one: 'Fyll ditt avfall i maskinens tratt.',
      two: 'Skruven drar och komprimerar ditt avfall in i containern.',
      three: 'Du får ett meddelande när containern är full.',
      four: 'Din renhållningsentreprenör hämtar containern för tömning.',
      five: 'Den tomma containern returneras och kopplas på igen.'
    },
    höjdpunkter: {
      rubrik_ett: 'Överlägsen komprimering - stor kapacitet',
      text_ett: 'Den koniskt utformade skruven ger upp till 60% högre komprimeringsgrad jämfört med en vanlig komprimator.',
      rubrik_två: 'Driftsäker och snabb procestid',
      text_två: 'En stationär skruvkomprimator är lämplig för stora mängder avfall som kräver snabb bearbetning, vilket minimerar väntetiden. Med en containervolym på upp till 30 m³ har anläggningen mycket stor kapacitet.',
      rubrik_lista: 'Stationär skruvkomprimator för många fraktioner:',
      lista: [
        'Stationär skruvenhet med flyttbar container',
        'Ökad kapacitet i containern',
        'Ökad komprimeringsgrad med upp till 60 % jämfört med en vanlig komprimator',
        'Kort procestid - även vid stora mängder per inmatning',
        'Robust och driftsäker konstruktion',
        'Vid tömning avlägsnas endast containern',
        'Kundanpassade lösningar med ett stort urval av tillval',
      ]
    },
  },
  {
    id: 'Stationära_skruvkomprimatorer',
    namn: 'Skruvkomprimatorer SK120-200-240-370',
    typ: 'Komprimatorer',
    volym: 'Mellan / Stor',
    kategori: ['Stationära skruvkomprimatorer'],
    serie: 'Stationära skruvkomprimatorer',
    avfallstyp: ['Plastfolie', 'PET flaskor', 'Aluminium burkar', 'Metall burkar', 'EPS Skumplast', 'Hårdplast', 'EPP / XPS Cellplast', 'Skumplast / EPE', 'PUR Polyuretaner'],
    beskrivning: 'Pressa voluminöst skum och EPS (Flamingo) extremt effektivt med en komprimeringsgrad på 50:1. Maskinen formar hanterbara block som lätt kan staplas på en pall.',
    imageUrl: '/product-pictures/SK/SK.PNG',
    thumbnails: ['/product-pictures/SK/SK_t1.PNG', '/product-pictures/SK/SK_t2.PNG', '/product-pictures/SK/SK_t3.PNG', '/product-pictures/SK/SK_t4.PNG', '/product-pictures/SK/SK_t5.PNG'],
    brochureUrl: ['/brochures/PDF_BROCHURE_SK.pdf', '/brochures/PDF_BROCHURE_SK.pdf', '/brochures/PDF_BROCHURE_SK.pdf', '/brochures/PDF_BROCHURE_SK.pdf', '/brochures/PDF_BROCHURE_SK.pdf'],
    utbalning: 'Automatisk',
    modeller: {
      Runi_SK120: ['Runi SK120', 'Fraktion: EPS', 'Kapacitet: 18 kg/time', 'Densitet: <350 kg/m3', 'Per dag: 80 kg'],
      Runi_SK200: ['Runi SK200', 'Fraktion: EPS', 'Kapacitet: 45 kg/timme', 'Densitet: <350 kg/m3', 'Per dag: 500 kg'],
      Runi_SK200T: ['Runi SK200 Twin', 'Fraktion: EPS', 'Kapacitet: 90 kg/timme', 'Densitet: <350 kg/m3', 'Per dag: 900 kg'],
      Runi_SK240: ['Runi SK240', 'Fraktion: EPS', 'Kapacitet: 75 kg/timme (EPS)', 'Densitet: <350 kg/m3 (EPS)', 'Per dag: 750 kg (EPS)'],
      Runi_SK370: [
        'Runi SK370', 
        'Fraktion: EPS', 
        'Kapacitet: 200 kg/timme (EPS)', 
        'Densitet: <350 kg/m3 (EPS)', 
        'Per dag: 4000 kg (EPS)', 
        'Andra fraktioner: Plastfolie, PET-flaskor, aluminium burkar, metall burkar, hårdplast, EPP / XPS, skum / EPE, PUR'
    ]
    },
    modeller_bilder: ['/product-pictures/SK/SK120.PNG', '/product-pictures/SK/SK200.PNG', '/product-pictures/SK/SK200T.PNG', '/product-pictures/SK/SK240.PNG', '/product-pictures/SK/SK370.PNG',],
    höjdpunkter: {
      rubrik_ett: 'Skapa hanterbara block av voluminöst EPS och skumavfall',
      text_ett: 'Skruvkomprimatorn förvandlar ditt fylliga och luftiga EPS eller skumavfall till komprimerade block. Dessa block tar minimalt med plats och kan staplas tills de hämtas.',
      rubrik_två: 'Allround komprimeringslösning',
      text_två: 'Skruvkomprimatorernas robusta konstruktion ger många användningsmöjligheter. Maskinerna kan komprimera de flesta typer av skum, såsom polystyren, EPS, XPS, EPP, EPE och PE-folie.',
      rubrik_lista: 'Skruvkomprimatorer SK120-200-230-370 komprimerar voluminöst skum och EPS:',
      lista: [
        'Komprimerar fylligt skum och EPS-avfall',
        'Stor volymreduktion (<50:1)',
        'Extra lång garantiperiod',
        'Effektiv nedbrytning av materialet',
        'Producerar högkomprimerade hanterbara block',
        'Automatisk process - minimalt behov av personal',
        'Sensorövervakning av materialet i maskinen',
        'Skydd mot överfyllning och tomgång',
      ]
    }
},
{
    id: 'Stationära_skruvkomprimatorer_dränering',
    namn: 'Skruvkomprimatorer med dränering SK240-370',
    typ: 'Komprimatorer',
    volym: 'Mellan / Stor',
    kategori: ['Stationära skruvkomprimatorer'],
    serie: 'Stationära skruvkomprimatorer',
    avfallstyp: ['Organiskt', 'Plastfolie', 'PET flaskor', 'Aluminium burkar', 'Metall burkar', 'Flytande'],
    beskrivning: 'Det höga presstrycket i en RUNI skruvkomprimator är idealisk när vätska behöver separeras från fast material. Skruvens höga vridmoment gör processen mer effektiv och energibesparande än andra metoder som centrifugering eller värmetorkning.',
    imageUrl: '/product-pictures/SKD/SKD.PNG',
    thumbnails: ['/product-pictures/SKD/SKD_t1.PNG', '/product-pictures/SKD/SKD_t2.PNG', '/product-pictures/SKD/SKD_t3.PNG', '/product-pictures/SKD/SKD_t4.PNG', '/product-pictures/SKD/SKD_t5.PNG'],
    brochureUrl: ['/brochures/PDF_BROCHURE_SKD.pdf', '/brochures/PDF_BROCHURE_SKD.pdf'], 
    modeller: {
      Runi_SK240D: [
        'Runi SK240',
        'Kapacitet: PET-flaskor - 5 000 st / timme (0,5 liter)',
        'Kapacitet: Aluminiumburkar - 7 000 st / timme (0,33 liter)',
        'Fraktioner: avvattning av etiketter',
        'Uppnått torrsubstans: papper etiketter: 65%, plastetiketter: 90%',
        'Kapacitet per timme: papper etiketter: 1 500 kg, plastetiketter: 400 kg',
        'Daglig kapacitet: papper etiketter: 30 ton, plastetiketter: 8 ton',
        'Fraktioner: avfall från plasttvättanläggning',
        'Uppnått torrsubstans: 75%',
        'Kapacitet per timme: 1 500 kg',
        'Daglig kapacitet: 36 ton'
      ],
      Runi_SK370D: [
        'Runi SK370',
        'Kapacitet: PET-flaskor - 20 000 st / timme (0,5 liter)',
        'Kapacitet: Aluminiumburkar - 20 000 st / timme (0,33 liter)',
        'Andra fraktioner: Organiskt, plastfilm, burkar, vätska',
        'Fraktioner: RDF/SRF, avfall från pappersbruk',
        'Uppnått torrsubstans: 75 %',
        'Kapacitet per timme: 5 - 10 ton',
        'Daglig kapacitet: 120-240 ton',
        'Fraktioner: matavfall',
        'Kapacitet: 2 000 kg per timme'
    ]
    },
    modeller_bilder: ['/product-pictures/SKD/SKD240.PNG', '/product-pictures/SKD/SKD370.PNG'],
    höjdpunkter: {
      rubrik_ett: 'Effektiv separation av vätskeinnehåll',
      text_ett: 'Maskinerna separerar vätskeinnehåll från t.ex. PET-flaskor. Inom biogasindustrin kan det våta restavfallet avvattnas. Tvättad PE-film kan torkas lätt och effektivt.',
      rubrik_två: 'Tömning och komprimering samtidigt',
      text_två: 'RUNI skruvkomprimatorer tömmer och komprimerar avfall i ett och samma arbetsmoment. Man säkerställer 100% destruktion, och kostnaderna för avfallshantering reduceras.',
      rubrik_lista: 'RUNI Modeller SK 240-370 - komprimerar och separerar vätska från fast avfall:',
      lista: [
        'Separation av vätska från fast avfall',
        'Lämplig för många olika applikationer',
        'Utökad garantitäckning',
        'Minimera mängden avfall till förbränning',
        'Automatisk drift - minimalt behov av personal',
        'En sensor övervakar fyllningsnivån',
        'Separat avfall kan återvinnas - med miljöfördelar',
        'Ingen risk för överfyllning eller tomgångskörning'
      ]
    }
  }
];

export default products;